%linkBase {
  text-decoration: underline;
  text-underline-offset: 0.25em;
  transition: all $transition-duration ease;
  color: $color-text;
  cursor: pointer;

  &.inverted {
    color: $color-accent-fresh;
  }

  &.noUnderline {
    text-decoration: none;
  }

  &.stealth {
    text-decoration: none;
  }

  &:hover,
  &:active {
    color: $color-brazil-primary;

    &.stealth {
      color: $color-text;
    }
  }
}

.link,
.textblock a:not(.btn),
.htmlblock a:not(.btn) {
  @extend %linkBase;
}
