html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $color-background-main;
  font-family: $font-primary;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  background-color: $color-accent-newengland;
  height: 1px;
}

.no-scroll {
  &--all {
    max-height: 100vh;
    overflow: hidden;
  }

  &--mobile {
    @include media-breakpoint-down(md) {
      max-height: 100vh;
      overflow: hidden;
    }
  }
}
