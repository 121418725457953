
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.container {
  max-width: $max-width;
  margin: $gutters-vertical auto;
  padding: 0 $gutters-horizontal-sm;

  @include media-breakpoint-up(md) {
    padding: 0 $gutters-horizontal-md;
  }
}

.wide {
  max-width: initial;
}

.twocolumns,
.threecolumns {
  justify-content: space-between;
  gap: $space-lg;

  @include media-breakpoint-up(md) {
    display: flex;

    > * {
      flex: 50%;
    }
  }
}

.threecolumns {
  @include media-breakpoint-up(md) {
    > * {
      flex: 50%;
    }
  }
}
