
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.item {
  flex: 0 0 100%;

  // IMPORTANT: Since we change behaviour on desktop to be on hover, this needs to work ONLY below desktop
  @include media-breakpoint-down(md) {
    &.isExpanded {
      .submenu {
        display: block;
      }
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &.isWhite {
    padding: $space-xs $space-md;
    background: $color-accent-fresh;
    font-size: 16px;
    border-bottom: 1px solid $color-border;
    min-height: 60px;
    color: $color-text;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.isExpandable:after {
    content: '+';
    font-weight: bold;
    font-size: 2rem;
    line-height: 0;
    text-decoration: none;
  }

  &.isExpanded:after {
    content: '-';
  }

  &.isSubmenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-sm $space-sm $space-sm $space-xl;
    border-bottom: 1px solid $color-border;
    color: $color-text;
    width: calc(100% - $space-xs);
  }

  &.isDetail {
    background-color: $color-text-negative;
    padding-left: calc($space-md * 2);
  }
}

.submenu {
  display: none;
}

.submenusWrapper {
  background: $color-rainforest-light;
}

.details {
  display: none;

  &.isExpanded {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .item {
    flex: 0 0 auto;

    &.isFull {
      flex: 0 0 100%;
    }

    &.isMobile {
      display: none;
    }

    &:hover {
      .link.isWhite {
        background-color: $color-text-negative;
        color: $color-text;
        text-decoration: none;
      }
    }

    &.isExpandable:hover {
      .submenu {
        display: flex;
        z-index: 2;
      }
    }
  }

  .link {
    display: block;
    background: transparent;
    border-bottom: none;
    color: $color-text-negative;

    &:hover {
      text-decoration: underline;
    }

    &.isWhite {
      padding: $space-sm;
      min-height: inherit;
      line-height: inherit;
      background-color: transparent;
      color: $color-text-negative;
      border-bottom: none;
    }

    &.isExpandable:after {
      content: none;
      display: none;
    }

    &.isSubmenu {
      display: block;
      padding: 0;
      border-bottom: none;
      text-transform: uppercase;
      font-weight: bold;
      font-size: $font-x-small;
      color: $color-text;

      &:hover {
        color: $color-error-primary;
      }
    }

    &.isDetail {
      margin: $space-xs 0;
      font-size: $font-x-small;
      color: $color-text;
      font-weight: normal;
      text-transform: none;

      &:hover {
        color: $color-error-primary;
      }
    }
  }

  .submenu {
    position: absolute;
    padding: $space-md $space-lg;
    background: $color-accent-fresh;
    border-bottom: $border-width solid $color-error-primary;
    width: 700px;
    max-height: 400px;
    left: 0;
    color: $color-text;
  }

  .submenusWrapper {
    background: $color-accent-fresh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    .item {
      flex: none;
      width: 33.333333%;
      margin: 0 0 $space-lg;
    }
  }

  .details {
    display: block;
    padding: $space-xs;
  }
}
