
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.item {
  flex: 0 0 100%;
  flex-basis: 50%;
  font-weight: normal;
  border-bottom: $border-width solid $color-accent-italian;

  &:nth-child(odd) {
    border-right: $border-width solid $color-accent-italian;
  }

  &.b2b {
    flex: 1 1 100%;
    list-style: none;
    border: none;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.icon {
  display: block;
  margin: 0 auto $space-xs;
  width: 25px;
  height: 25px;

  &.b2b {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-right: $space-xs;
  }
}

.link {
  display: block;
  background: $color-rainforest-primary;
  text-align: center;
  padding: $space-md 0;
  color: $color-text-negative;

  &.b2b {
    display: flex;
    align-items: center;
    background: $color-rainforest-dark;
    padding: $space-sm $space-md;
    min-height: $navbar-height;
  }

  &:hover {
    text-decoration: underline;
  }
}
