
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.ratingsSectionWrapper {
  margin: $space-sm 0;
}

.ratingSummaryWrapper {
  display: flex;
  align-items: center;
}

.images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $space-md;
}
