
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -2px;
  background: $color-brazil-primary;
  color: $color-text-negative;
  font-size: $font-x-small;
  border-radius: 50%;
  padding: 1px;
  text-align: center;
  min-width: 16px;
  height: 16px;
  font-weight: normal;
}

@include media-breakpoint-up(lg) {
  .icon {
    width: 18px;
    height: 18px;
  }

  .quantity {
    min-width: 14px;
    height: 14px;
    font-size: 10px;
  }
}
