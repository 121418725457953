
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
@import 'src/styles/buttons';

button.buttonBase,
a.buttonBase {
  @extend %buttonBase;
}

button.primary,
a.primary {
  @extend %primary;
}

button.secondary,
a.secondary {
  @extend %secondary;
}

button.tertiary,
a.tertiary {
  @extend %tertiary;
}

button.ghost,
a.ghost {
  @extend %ghost;
}

button.inline,
a.inline {
  padding: 0;
  height: auto;
  font-size: inherit;
}

button.primaryCta,
a.primaryCta {
  @extend %primary-cta;
}

button.tertiaryCta,
a.tertiaryCta {
  @extend %tertiary-cta;
}

/** Mantine Styles */
.section svg {
  margin: 0;
}
