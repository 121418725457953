// https://sass-lang.com/documentation/style-rules/placeholder-selectors
%buttonBase {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: $space-sm;
  padding-right: $space-sm;
  line-height: 38px;
  font-size: $font-sub;
  font-weight: bold;
  border-radius: $border-radius;
  border: $border-radius solid transparent;
  cursor: pointer;
  transition: border-color, background-color, color $transition-duration ease-out;

  &:disabled {
    cursor: not-allowed;
  }
}

%primary {
  color: $color-text-negative;
  background-color: $color-cta-primary;
  border-color: $color-cta-primary;

  &:disabled {
    opacity: 0.5;
    color: $color-text-negative;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: $color-cta-hovered;
    border-color: $color-cta-hovered;

    &:disabled {
      color: $color-text-negative;
      background-color: $color-cta-primary;
      border-color: $color-cta-primary;
    }
  }
}

%secondary {
  background-color: $color-accent-fresh30;
  color: $color-text;
  border: $border-width solid $color-accent-italian;

  &:disabled {
    background-color: $color-accent-drying;
    opacity: 0.5;
    color: $color-text-negative;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: $color-text;
    border-color: $color-text;
    color: $color-text-negative;

    &:disabled {
      background-color: $color-accent-drying;
      color: $color-text-negative;
      border-color: transparent;
    }
  }

  // secondary inverted
  &.inverted {
    background-color: $color-accent-italian30;
    color: $color-text-negative;
    border: $border-width solid $color-accent-fresh;

    &:disabled {
      background-color: $color-accent-drying;
      opacity: 0.5;
      color: $color-text-negative;
      border-color: $color-accent-drying;
    }

    &:hover,
    &:active,
    &.active {
      background-color: $color-accent-fresh;
      border-color: $color-text-negative;
      color: $color-text;
      border-color: $color-accent-fresh;

      &:disabled {
        background-color: $color-accent-drying;
        color: $color-text-negative;
        border-color: $color-accent-drying;
      }
    }
  }
}

%tertiary {
  background-color: transparent;
  color: $color-ethiopia-primary;
  border: none;

  &:disabled {
    background-color: transparent;
    color: $color-accent-drying;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: transparent;
    color: $color-rainforest-primary;
    border: none;

    &:disabled {
      background-color: transparent;
      color: $color-accent-drying;
      border-color: transparent;
    }
  }

  // tertiary inverted
  &.inverted {
    &:disabled {
      background-color: transparent;
      color: $color-accent-drying;
      border-color: transparent;
    }

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: $color-ethiopia-light;
      border: none;

      &:disabled {
        background-color: transparent;
        color: $color-accent-drying;
        border-color: transparent;
      }
    }
  }
}

%ghost {
  background-color: transparent;
  color: $color-accent-italian;
  border: none;
  text-decoration-line: underline;
  text-underline-offset: 0.25em;
  font-weight: 400;

  &:disabled {
    background-color: transparent;
    color: $color-accent-drying;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: transparent;
    color: $color-brazil-primary;
    border: none;

    &:disabled {
      background-color: transparent;
      color: $color-accent-drying;
      border-color: transparent;
    }
  }

  // ghost inverted
  &.inverted {
    color: $color-accent-fresh;

    &:disabled {
      background-color: transparent;
      color: $color-accent-newengland;
      border-color: transparent;
    }

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: $color-brazil-primary;
      border: none;

      &:disabled {
        background-color: transparent;
        color: $color-accent-newengland;
        border-color: transparent;
      }
    }
  }
}

%primary-cta {
  color: $color-text-negative;
  background-color: $color-indigo-primary;
  border-color: $color-indigo-primary;

  &:disabled {
    opacity: 0.5;
    color: $color-text-negative;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: $color-indigo-light;
    border-color: $color-indigo-light;

    &:disabled {
      color: $color-text-negative;
      background-color: $color-indigo-primary;
      border-color: $color-indigo-primary;
    }
  }
}

%tertiary-cta {
  background-color: transparent;
  color: $color-indigo-primary;
  border: none;

  &:disabled {
    background-color: transparent;
    color: $color-accent-drying;
    border-color: transparent;
  }

  &:hover,
  &:active,
  &.active {
    background-color: transparent;
    color: $color-indigo-primary;
    border: none;

    &:disabled {
      background-color: transparent;
      color: $color-accent-drying;
      border-color: transparent;
    }
  }

  &.inverted {
    &:disabled {
      background-color: transparent;
      color: $color-accent-drying;
      border-color: transparent;
    }

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: $color-indigo-primary;
      border: none;

      &:disabled {
        background-color: transparent;
        color: $color-accent-drying;
        border-color: transparent;
      }
    }
  }
}

%btn-reset {
  padding: 0;
  border: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

.btn {
  @extend %buttonBase;
}

// for special cases
.btn-reset {
  @extend %btn-reset;
}

.btn--primary,
.textblock .btn.green,
.textblock .btn.subscription {
  @extend %buttonBase;
  @extend %primary;
}

.btn--secondary,
.textblock .btn.blue,
.textblock .btn.red,
.textblock .btn.projects {
  @extend %buttonBase;
  @extend %secondary;
}
