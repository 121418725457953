@font-face {
  font-display: swap;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  src: url('/assets/fonts/AlbertSans-VariableFont_wght.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Noe Display';
  src: url('/assets/fonts/noe-display-bold.woff2') format('woff2');
}

body {
  line-height: 1.15;
  font-size: 16px;
  font-family: 'Albert Sans', Arial, Helvetica, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'kern' 1, 'liga' 1, 'ss01' 1, 'ss02' 0, 'ss03' 0, 'ss04' 0, 'ss05' 1, 'ss06' 1, 'frac' 0;
  font-kerning: normal;
}

%readability {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-wrap: pretty;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.sub {
  font-weight: 700;
  flex: 0 0 100%;
  @extend %readability;
}

h4,
.h4 {
  font-size: $font-medium;
  line-height: 1.15;
}

h3,
.h3 {
  font-size: $font-large;
  line-height: 1.35;
}

h2,
.h2 {
  font-size: 24px;
  font-size: $font-x-large;
  line-height: 1.15;
}

.h2 {
  &--overlayed {
    padding: $space-sm;
    max-width: $max-width;
    margin: 0 auto;
    color: $color-text-negative;
  }
}

h1,
.h1 {
  font-size: 36px;
  font-size: clamp(36px, 5vw, 56px);
  font-weight: normal;
  line-height: 1.15;
  letter-spacing: -1.8px;
  font-family: $font-headline;
}

.h1 {
  &--overlayed {
    padding: $space-sm;
    max-width: $max-width;
    margin: 0 auto;
    color: $color-text-negative;
  }
}

.sub {
  font-size: $font-sub;
  line-height: 1.6;
}

.h-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

p,
.p {
  font-size: $font-normal;
  line-height: 1.6;
  margin: $space-sm 0;
  @extend %readability;
}

.subtitle,
.subtitle p,
.intro,
.intro p {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
  @extend %readability;
}

strong,
.strong,
b,
.bold {
  font-weight: 700;
}

small,
.small {
  font-size: $font-small;
  font-weight: 500;
  line-height: 1.4;
}

.x-small {
  font-size: $font-x-small;
  line-height: 1.5;
}

blockquote {
  max-width: 35rem;
  margin-bottom: $space-sm;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;

  p {
    font-size: 26px;
    line-height: 1.4;
  }

  &:before {
    content: '';
    display: block;
    width: 70px;
    height: 3px;
    background-color: $color-text;
  }

  @include media-breakpoint-up(md) {
    &--left {
      float: left;
      max-width: 17rem;
      margin: $space-xs $space-xs 0 0;
    }
  }
}

$bullet-size: 0.4rem;
$number-size: 1rem;
$list-offset: $space-md;

.list {
  line-height: 1.65;
  font-size: $font-normal;
  margin: $space-sm 0 $space-sm $list-offset;
  padding-left: $list-offset;

  li {
    margin: $space-xs 0;
    position: relative;

    &:before {
      background: $color-brazil-dark;
      border-radius: 100%;
      margin-right: $list-offset;
      position: absolute;
      top: 0.6em;
    }
  }
}

ul.list {
  li:before {
    content: '';
    height: $bullet-size;
    width: $bullet-size;
    display: inline-block;
    left: -($list-offset + $bullet-size * 0.5);
  }
}

ol.list {
  counter-reset: orderedlist;

  li:before {
    content: counter(orderedlist);
    counter-increment: orderedlist;
    height: $number-size;
    width: $number-size;
    color: $color-accent-fresh;
    font-weight: bold;
    font-size: $font-x-small;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: -($list-offset + $number-size * 0.5);
  }
}

.textblock ul,
.textblock ol {
  @extend .list;
}

.headlineblock + .textblock {
  margin-top: -$gutters-vertical + $space-sm;
}

details {
  @extend .p;

  summary {
    cursor: pointer;
  }
}
