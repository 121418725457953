
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
:export {
  //
  // Colors
  //
  color-laos-primary: $color-laos-primary;

  color-kenya-primary: $color-kenya-primary;
  color-kenya-tint: $color-kenya-tint;
  color-kenya-medium: $color-kenya-medium;
  color-kenya-light: $color-kenya-light;
  color-kenya-whisper: $color-kenya-whisper;

  color-brazil-dark: $color-brazil-dark;
  color-brazil-primary: $color-brazil-primary;
  color-brazil-medium: $color-brazil-medium;
  color-brazil-light: $color-brazil-light;
  color-brazil-whisper: $color-brazil-whisper;

  color-indonesia-primary: $color-indonesia-primary;
  color-indonesia-tint: $color-indonesia-tint;
  color-indonesia-medium: $color-indonesia-medium;
  color-indonesia-light: $color-indonesia-light;
  color-indonesia-whisper: $color-indonesia-whisper;

  color-ethiopia-primary: $color-ethiopia-primary;
  color-ethiopia-tint: $color-ethiopia-tint;
  color-ethiopia-medium: $color-ethiopia-medium;
  color-ethiopia-light: $color-ethiopia-light;
  color-ethiopia-whisper: $color-ethiopia-whisper;

  color-colombia-dark: $color-colombia-dark;
  color-colombia-primary: $color-colombia-primary;
  color-colombia-medium: $color-colombia-medium;
  color-colombia-light: $color-colombia-light;
  color-colombia-whisper: $color-colombia-whisper;

  color-info-primary: $color-info-primary;
  color-info-medium: $color-info-medium;
  color-info-light: $color-info-light;

  color-success-primary: $color-success-primary;
  color-success-medium: $color-success-medium;
  color-success-light: $color-success-light;

  color-error-primary: $color-error-primary;
  color-error-medium: $color-error-medium;
  color-error-light: $color-error-light;

  color-warning-primary: $color-warning-primary;
  color-warning-medium: $color-warning-medium;
  color-warning-light: $color-warning-light;

  //
  // Primary Colors
  //
  color-rainforest-primary: $color-rainforest-primary;
  color-rainforest-dark: $color-rainforest-dark;
  color-rainforest-light: $color-rainforest-light;

  //
  // Accent Colors
  //
  color-mantis-primary: $color-mantis-primary;
  color-mantis-median: $color-mantis-median;
  color-mantis-opaque: $color-mantis-opaque;

  color-explorer-primary: $color-explorer-primary;
  color-explorer-median: $color-explorer-median;
  color-explorer-opaque: $color-explorer-opaque;

  color-bittersweet-primary: $color-bittersweet-primary;
  color-bittersweet-median: $color-bittersweet-median;
  color-bittersweet-opaque: $color-bittersweet-opaque;

  color-plush-primary: $color-plush-primary;
  color-plush-median: $color-plush-median;
  color-plush-opaque: $color-plush-opaque;

  color-fandango-primary: $color-fandango-primary;
  color-fandango-median: $color-fandango-median;
  color-fandango-opaque: $color-fandango-opaque;

  color-plum-primary: $color-plum-primary;
  color-plum-median: $color-plum-median;
  color-plum-opaque: $color-plum-opaque;

  color-indigo-primary: $color-indigo-primary;
  color-indigo-median: $color-indigo-median;
  color-indigo-opaque: $color-indigo-opaque;

  color-cerulean-primary: $color-cerulean-primary;
  color-cerulean-median: $color-cerulean-median;
  color-cerulean-opaque: $color-cerulean-opaque;

  color-aquamarine-primary: $color-aquamarine-primary;
  color-aquamarine-median: $color-aquamarine-median;
  color-aquamarine-opaque: $color-aquamarine-opaque;

  //
  // Guidance Colors
  //
  color-cta-primary: $color-cta-primary;
  color-cta-hovered: $color-cta-hovered;

  //
  // Action Colors
  //
  color-info-primary: $color-info-primary;
  color-info-medium: $color-info-medium;
  color-info-text: $color-info-text;

  color-error-primary: $color-error-primary;
  color-error-medium: $color-error-medium;
  color-error-text: $color-error-text;

  color-success-primary: $color-success-primary;
  color-success-medium: $color-success-medium;
  color-success-text: $color-success-text;

  color-warning-primary: $color-warning-primary;
  color-warning-medium: $color-warning-medium;
  color-warning-text: $color-warning-text;

  //
  // Shades
  //
  color-accent-italian: $color-accent-italian;
  color-accent-french: $color-accent-french;
  color-accent-vienna: $color-accent-vienna;
  color-accent-fullcity: $color-accent-fullcity;
  color-accent-city: $color-accent-city;
  color-accent-american: $color-accent-american;
  color-accent-newengland: $color-accent-newengland;
  color-accent-cinnamon: $color-accent-cinnamon;
  color-accent-drying: $color-accent-drying;
  color-accent-fresh: $color-accent-fresh;

  //
  // Opacities
  //
  color-accent-italian30: $color-accent-italian30;
  color-accent-italian60: $color-accent-italian60;
  color-accent-italian90: $color-accent-italian90;
  color-accent-fresh30: $color-accent-fresh30;
  color-accent-lineargradient138: $color-accent-lineargradient138;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.colorSwatch {
  display: flex;
  width: 26rem;
  height: 8rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
  border: 1px solid $color-laos-primary;

  div:first-child {
    width: 50%;
    height: 100%;
  }

  div:last-child {
    padding: 1rem;
  }
}
