
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.algoliaSearch {
  :global(.ais-SearchBox) {
    flex: 1;
  }

  :global(.ais-SearchBox-form) {
    display: flex;
  }

  :global(.ais-SearchBox-submit) {
    display: none;
  }

  :global(.algolia-autocomplete) {
    flex: 1 0 auto;
    background-color: transparent;
  }

  :global(.ais-SearchBox-input) {
    padding: $space-sm 0 $space-sm $space-xs;
    width: 100%;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    -webkit-appearance: none;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  :global(.ais-SearchBox-reset) {
    border: none;
    background: transparent;
  }

  .reset {
    background-color: $color-accent-drying;
    border-radius: 50%;
  }

  @include media-breakpoint-up(lg) {
    :global(.ais-SearchBox-form) {
      display: flex;
      width: 250px;
      position: relative;
    }

    :global(.ais-SearchBox-input) {
      order: 2;
    }

    :global(.ais-SearchBox-submit) {
      display: block;
      background-color: transparent;
      color: $color-text-negative;
      border: none;
      order: 1;
    }

    :global(.ais-SearchBox-input) {
      padding-right: $space-md;
      max-height: 40px; // safari fix
      background-color: transparent;
      color: $color-text-negative;
      border: 1px solid transparent;
      border-radius: 0;
      font-size: 0.825rem;

      &::placeholder {
        color: $color-text-negative;
      }

      &:focus {
        border-color: $color-info-primary;
      }
    }

    :global(.ais-SearchBox-reset) {
      position: absolute;
      top: 0.7rem;
      right: $space-xs;
      color: $color-accent-fresh;
    }
  }
}

.isMobileOpen:after {
  position: fixed;
  top: $navbar-height;
  left: 0;
  bottom: 0;
  right: 0;
  background: $color-accent-italian;
  opacity: 0.7;
  content: '';
  z-index: -1;
}

button.menuButton {
  padding: $space-sm $space-sm $space-sm $space-xs;
  vertical-align: sub;
  color: $color-text-negative;

  .magnifier {
    display: block;

    .isMobileOpen & {
      display: none;
    }
  }

  .close {
    display: none;

    .isMobileOpen & {
      display: block;
    }
  }
}

.query {
  display: none;
  position: absolute;
  top: $navbar-height;
  left: 0;
  padding: $space-sm;
  width: 100%;
  background-color: $color-rainforest-dark;

  .isMobileOpen & {
    display: block;
  }
}

.queryInner {
  display: flex;
  background-color: $color-accent-fresh;
  border-radius: 4px;
}

@include media-breakpoint-up(md) {
  .query {
    width: 45%;
  }
}

@include media-breakpoint-up(lg) {
  .menuButton {
    display: none;
    width: auto;
    padding: 0 $space-xs !important;
    background-color: $color-rainforest-dark !important;

    .magnifier {
      display: block !important;
    }

    .close {
      display: none !important;
    }
  }

  .query {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    padding: 0;
    background-color: $color-rainforest-dark;
  }

  .queryInner {
    background-color: transparent;
  }

  .isMobileOpen:after {
    display: none;
  }
}
